import React, { Fragment } from "react"
import { graphql, Link } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { MDXProvider } from "@mdx-js/react"
import Layout from "../components/layout"
import ProjectBlock from "../components/projectBlock"
import TableOfContent from "components/mdx/tableOfContent"
import SEO from "../components/seo"
import { getImage, getSrc, GatsbyImage } from "gatsby-plugin-image"

import getRandomSubarray from "../utils/getRandomSubarray"

import shortcodes from "../components/mdx"
import ProjectBlockConcise from "components/projectBlockConcise"

// import './styles.css';

const MdxPage = ({
  location,
  data: {
    mdx: { frontmatter, body, slug, headings },
    projects,
  },
}) => {
  const { title, description, category, embeddedImages, tableOfContent } =
    frontmatter
  const isHomePage = location.pathname !== "/"

  const otherProjects = projects.edges.filter((e) => e.node.slug !== slug)
  const NUM = 4
  const randomProjects = getRandomSubarray(otherProjects, NUM)

  return (
    <Layout
      category={category}
      location={location}
      pageType={"project-detail"}
      tableOfContent={tableOfContent}
      headings={headings}
    >
      {/* front matter */}
      <SEO
        article
        title={title}
        description={description}
        image={getSrc(embeddedImages[0])}
      ></SEO>

      {!frontmatter.noTitleBlock && (
        <ProjectBlock
          className=""
          isProjectDetailPage={true}
          slug={"/" + slug + "#main-content"}
          frontmatter={frontmatter}
        ></ProjectBlock>
      )}

      <div className="flex justify-between">
        <MDXProvider components={shortcodes}>
          <article className=" w-full md:min-w-[24rem]  pb-16 mt-6">
            <MDXRenderer localImages={embeddedImages}>{body}</MDXRenderer>
          </article>
        </MDXProvider>

        {/* {tableOfContent && (
          <div
            className="sticky top-0 h-64
          transition-all hidden lg:block "
          >
            <TableOfContent headings={headings}></TableOfContent>
          </div>
        )} */}
      </div>

      {/* only show more projects if this is not the about page */}
      {slug !== "about/" && (
        <Fragment>
          <hr
            className={`mt-36 border-t-2 border-dashed border-black pb-4`}
          ></hr>
          <div className={` py-2 border-dashed border-black text-center`}>
            ↓ more projects ↓
          </div>
        </Fragment>
      )}
      {/* only show more projects if this is not the about page */}
      <div
        id="main"
        className="flex flex-col md:grid md:grid-cols-2 md:gap-x-8 "
      >
        {slug !== "about/" &&
          randomProjects.map((e, i) => {
            const slug = e.node.slug
            const frontmatter = e.node.frontmatter
            return (
              <ProjectBlockConcise
                slug={slug + "#main-content"}
                frontmatter={frontmatter}
                i={i}
                key={i}
              ></ProjectBlockConcise>
            )
          })}
      </div>
    </Layout>
  )
}

export const query = graphql`
  query ($id: String) {
    mdx(id: { eq: $id }) {
      frontmatter {
        title
        category
        displayedCategory
        date
        year
        displayed_time
        position
        description
        notes
        tags
        role
        collaborators
        contributors
        external
        publication
        social
        video
        github
        tableOfContent
        noTitleBlock
        featured
        skills
        embeddedImages {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 1200)
          }
        }
      }
      headings {
        depth
        value
      }
      tableOfContents
      body
      slug
    }
    projects: allMdx(
      filter: {
        fileAbsolutePath: { regex: "/projects/" }
        frontmatter: { visible: { eq: true } }
      }
      sort: {
        fields: [frontmatter___year, frontmatter___position]
        order: [DESC, DESC]
      }
    ) {
      edges {
        node {
          body
          slug
          frontmatter {
            title
            category
            displayedCategory
            date
            year
            displayed_time
            position
            featured
            description
            notes
            tags
            role
            collaborators
            contributors
            publication
            social
            external
            video
            github
            overrideLink
            embeddedImages {
              childImageSharp {
                gatsbyImageData(width: 1600)
              }
            }
          }
        }
      }
    }
  }
`

export default MdxPage
